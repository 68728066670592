export default function useCacheOnErrorRevert() {
  const queryClient = useQueryClient();

  const onError = (error: unknown, vars: unknown, context: Context) => {
    if (context?.mutationMap) {
      // Rollback the cache update

      for (const { key, previousData } of context.mutationMap) {
        queryClient.setQueryData(key, previousData);
      }
    }
  };
  return {
    onError
  };
}
